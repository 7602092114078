import { get } from '@/utils/request';

const getSkuListBySpuId = function(storeId, prodCatId, name) {
  return get(
    `/f/api/frontsku/skuList?storeId=${storeId}&prodCatId=${prodCatId}&name=${name}`
  )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

//根据spuId查询商品详细信息(商品详情)
const getSkuKeys = function(spuId) {
  return get(`/f/api/frontsku/skuKeys?spuId=${spuId}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export { getSkuListBySpuId, getSkuKeys };
