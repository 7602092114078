<template>
  <div class="details w-1920">
    <header-user></header-user>
    <store-search></store-search>
    <store-image></store-image>
    <store-menu ref="menu"></store-menu>
    <details-wares :spu="spu" :jifen="jifen"></details-wares>
    <details-introduce :spu="spu" v-if="spu.spuId"></details-introduce>
    <foot></foot>
    <cart></cart>
  </div>
</template>

<script>
import header_user from "@/components/header";
import store_search from "../store/store-search";
import store_image from "../store/store-image";
import store_menu from "../store/store-menu";
import details_crumbs from "./details-crumbs";
import details_wares from "./details-wares";
import details_introduce from "./details-introduce";
import baoyou from "@/components/order/baoyou";
import { getSpuInfo } from "@/api/spu";
import foot from "@/components/foot";
import cart from "@/components/cart";
import CustomEvents from "@/utils/customEvents";

export default {
  data() {
    return {
      spu: {},
      jifen: "",
      jifen1: "",
    };
  },
  provide() {
    const bus = new CustomEvents();
    return {
      bus,
    };
  },
  components: {
    "header-user": header_user,
    "store-search": store_search,
    "store-image": store_image,
    "store-menu": store_menu,
    "details-crumbs": details_crumbs,
    "details-wares": details_wares,
    "details-introduce": details_introduce,
    baoyou: baoyou,
    foot: foot,
    cart: cart,
  },
  created() {
    this.jifen = this.$route.query.jifen;
    this.$nextTick(() => {
      this.$refs.menu.setSelectAll();
    });
    let spuId = this.$route.params.spu;
    getSpuInfo(spuId).then((res) => {
      this.spu = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.details {
  position: relative;
  background: #f5f5f5;
}
</style>
