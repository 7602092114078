<template> </template>

<script>
import { classifyMenu, BalanceMenu } from '@/api/store';

import mixins_store from './mixins-default';

export default {
  mixins: [mixins_store],
  data() {
    return {
      classify: [],
      selectMenu: -1,
    };
  },
  created() {
    this.$nextTick(() => {
      classifyMenu(this.storeId).then((data) => {
        this.classify = data;
      });
      BalanceMenu(this.storeId).then((data) => {
        this.menuData = data;
      });
    });
  },
  methods: {
    // handleMenuClick(storeProdCatId, parentId) {
    //   this.$router.push({
    //     name: 'store_classify',
    //     params: {
    //       sid: this.storeId,
    //       selectMenu: parentId,
    //     },
    //     query: {
    //       prodCatId: storeProdCatId,
    //     },
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.classify {
  &-block {
    background: #fff;
    padding-top: 1px;
  }

  border-top: 1px solid #ededed;
  padding-left: 30px;

  &:first-of-type {
    border-top: 0px;
  }

  &:last-of-type {
    padding-bottom: 20px;
  }

  &-title {
    font-size: 18px;
    font-weight: 800;
    margin: 20px 0px;
  }

  &-content {
    padding-bottom: 10px;

    &-item {
      margin: 10px 0px;
    }
  }
}
</style>
