import {
  postextra, //{code:0}
  getextra, //{code:0}
  GetPromiseData, //data:""
  PostPromiseData, //data:""
  GetextraPromiseData,
  DraftPromiseData,
} from '@/utils/request';

//获取商品的详细信息
export function getWares(spuId) {
  return new Promise((resolve, reject) => {
    GetextraPromiseData(
      "f/api/frontsku/getwares",
      {
        spuId,
      },
      {
        // timeout: 10000,
      }
    )
      .then((data) => {
        resolve({
          spu: data.spu,
          sku: {
            ...(function () {
              var resultSpu = {};
              Array.prototype.concat(data.sku).forEach((x) => {
                resultSpu[x.skuId] = x;
              });
              return resultSpu;
            })(),
          },
          skuAttr: [
            ...(function () {
              var resultskuAttr = [];
              function concat(obj) {
                if (obj instanceof Array) {
                  resultskuAttr = resultskuAttr.concat(obj);
                }
              }
              Array.prototype.concat(data.skuAttr).forEach((x) => {
                if (x instanceof Array) {
                  concat(x);
                } else if (typeof x === "string") {
                  concat(eval(`(${x})`));
                }
              });
              return resultskuAttr;
            })(),
          ],
          spuAttr: data.spuAttr,
          disable: data.disable,
          unit: {
            list: Array.prototype.concat(data.unit).map((x) => {
              return {
                label: x.dictLabel,
                key: x.dictValue,
              };
            }),
            exchange: (data.exchange + "").replace(/ /g, ""),
          },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getSkuIdByVerifyKeyVo2(spuId, keyList) {
  return postextra("f/api/frontspu/getSkuIdByVerifyKeyVo2", {
    keyList: keyList,
    spuId: spuId,
  });
}

//查询商品是否关注
export function isshopcollectsup(spuId) {
  return GetextraPromiseData("api/collectspu/isshopcollectsup", {
    spuId: spuId,
  });
}
export function insertshopcollectsup(spuId) {
  return postextra("api/collectspu/insertshopcollectsup", {
    spuId: spuId,
  });
}
export function deleteshopcollectsup(spuId) {
  return getextra("api/collectspu/deleteshopcollectsup", {
    spuId: spuId,
  });
}

/**
 * 将选中的key和系统spuId统一提交后台，
 * 获取禁选的属性项,判断获取的keyList是否符合一个完整的spu商品，
 * 同时将新选中的key提交后台，判断新选选中的key和原来选中的key是否同属于某个商品
 */
export function getVerifyKeyAndSpuId(keyList, key, spuId) {
  return postextra("/f/api/frontspu/getVerifyKeyAndSpuId", {
    keyList: keyList,
    key: key,
    spuId: spuId,
  });
}

export function getSkuIdByVerifyKeyVo(keyList, spuId) {
  return postextra("/f/api/frontspu/getSkuIdByVerifyKeyVo", {
    keyList: keyList,
    spuId: spuId,
  });
}
