export default [
  {
    dictLabel: "普通右旋国产阀",
    dictValue: "A",
  },
  {
    dictLabel: "反丝左旋",
    dictValue: "B",
  },
  {
    dictLabel: "普通右旋捷仪阀",
    dictValue: "C",
  },
  {
    dictLabel: "钢瓶捷仪阀",
    dictValue: "D",
  },
  {
    dictLabel: "不锈钢阀+涂层[腐蚀性气体]",
    dictValue: "E",
  },
  {
    dictLabel: "研磨",
    dictValue: "F",
  },
  {
    dictLabel: "铝合金气瓶+不锈钢阀",
    dictValue: "I",
  },
  {
    dictLabel: "钢瓶不锈钢瓶阀",
    dictValue: "H",
  },
];
