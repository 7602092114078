import {get,post, request} from '@/utils/request';


/***
 * 提交订单
 */

const submitOrder = function (data){
    return post('/api/orderbasic/submitOrder',data).then(res=>{return res.data}).catch(err=>console.log(err))
}

/**
 * 提交临时订单
 */

const submitOrderTemp = function (data){
    return post('/api/orderbasic/submitOrderTemp',data).then(res=>{return res.data}).catch(err=>console.log(err))
}

const getOrderTemp = function (){
    return get('/api/orderbasic/getOrderTemp').then(res=>{return res.data}).catch(err=>console.log(err))
}



export {submitOrder,submitOrderTemp,getOrderTemp}
