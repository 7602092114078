import bottles from "./wares.bottle.code.js";
import { get } from '@/utils/request';
import { isLogin } from "@/utils/public";
import { getWares, getSkuIdByVerifyKeyVo2 } from "@/api/details";
import { isshopcollectsup, deleteshopcollectsup, insertshopcollectsup } from "@/api/details";
import { getSaleAttr } from "@/api/productAttr";
import { getSkuKeys } from "@/api/sku";
import { singleinsertCart, moreinsertCart } from "@/api/cart";
import { submitOrderTemp } from "@/api/order.js";

const unitlist = ["mol/mol", "㎎/m³","㎎/m³(20℃)", "%", "%LEL", "mgc/m³","mgc/m³(20℃)", "mgs/m³", "mgs/m³(20℃)"];

export default {
  data() {
    return {
      bottleTypeList: bottles,

      topShow: false, //层罩的显示与隐藏
      rShow: false, //层罩的显示与隐藏
      imgList: [], //商品小图list
      activateImage: "", //当前展示的商品大图图片url地址
      activateIndex: 0, //小图图片选中样式序列号
      i: 0,

      TabIndex: 0, // 单位选中下表
      unit: "mol/mol",

      subscribe: false, // 关注 关注状态
      couponList: [], //优惠券
      modeFlag: false, // 没看懂原始需求，（废弃）
      attrData: [], //商品的销售规格
      hanliang: 0, // 含量输入框
      unitlist: ["mol/mol", "㎎/m³","㎎/m³(20℃)", "%", "%LEL", "mgc/m³","mgc/m³(20℃)", "mgs/m³", "mgs/m³(20℃)"], //确认含量单位
      selectFLag: false, //商品属性是否全部选择
      selectSku: {}, //当前选择的商品属性所对应的sku
      spuNum: "", //确定的商品编号
      addattrValueList: [], //暂存的组分列表
      spuId: "", // 传过来的参数
      skuId: "", // 传过来的参数
      counter: 1, /// 购买数量
      doJf: false, /// 是否开启积分
      disableAttr: [], /// 禁用的属性(xx:xxx)
      zancunprice: 0,

      pathKeys: [], //商品销售规格的路径（xx:xxx;xx:xxx;xx:xxx;xx:xxx）
      selectTree: [], // 类目选项二维数组（attrData的dataList层）
      isHanLiang: false, // 是否有确认含量
      selectKeys: [], // 选中的商品key(xx:xxx)

      zufenbmd: [], // 组分白名单（再多组分场景下，控制多祖分选项可以任意选择）
      disablePath: {}, // 禁用的路径，在加载产品信息时便利生成 ({ 'xx:xxx':[xx:xxx,xx:xxx]，'xx:xxx':[xx:xxx,xx:xxx] })

      duozufen: false, // 是否是多祖分，如果是多祖分，这里是多组分位于attrData内的下表,否则则是false
      hanliangList: [], // 浓度集合，所有组分下的浓度集合

      qitizufen: false, // 是否是气体商品，true是，false不是
      rongyezufen: false, // 是否是液体商品，true是，false不是（没用上）

      lock: false, // 是否正在编辑多组分含量（点击暂存了）
      lockItem: [], // 编辑多组分含量时禁用的选项
      lockValue: "", // 除去多组分和浓度范围外，其他选项的名称
      lockKeys: "", // 除去多组分和浓度范围外，其他选项的keys
      lockGroupItem: [],
      saleAttrGroup: [],
      nextOptions: false,
    };
  },
  created() {
    this.spuId = this.$route.params.spu; //初始化获取spuId
    this.skuId = this.$route.query.sku; //初始化获取skuId
    this.counter = this.$route.query.count || 1; //初始化获取购买数量
    this.doJf = this.$route.query.jifen == 1; //初始化获取当前积分
    this.$nextTick(() => {
      //获取商品到的详细信息
      getWares(this.spuId).then((data) => {
        this.form = data;
        //商品图片列表
        this.imgList = this.form.spu.image.slice(0, 4);
        //当前展示的商品大图图片url地址
        this.activateImage = this.form.spu.image[0].url;
        //小图图片选中样式序列号
        this.activateIndex = 0;
      });
    });

    this.isshopcollectsup();
    this.getCouponList();
    this.getSaleAttr(true);
  },
  computed: {
    // 格式化商品价格
    realPrice2() {
      if (this.$parent.jifen == 1) {
        if (this.addattrValueList && this.addattrValueList.length > 0) {
          return this.zancunprice2;
        } else {
          return this.selectFLag ? this.selectSku.exchangeScore : this.spu.exchangeScoreSection;
        }
      }
    },
    // 格式化商品价格
    realPrice() {
      try {
        if (this.addattrValueList && this.addattrValueList.length > 0) {
          return this.zancunprice;
        } else {
          return this.selectFLag ? this.selectSku.salePrice.toFixed(2) : this.spu.priceSection;
        }
      } catch {
        return this.spu.priceSection;
      }
    },
    image() {
      //获取图片列表，如果sku存在图片返回sku的图片，无则返回spu中的图片
      return Array.prototype.concat((this.sku && this.sku.image) || (this.form && this.form.sku && this.form.spu.image) || []);
    },
  },
  props: ["spu"],
  filters: {},

  methods: {
    getKeys(item) {
      return item.prodAttrId + ":" + item.prodAttrDataId;
    },
    /// 选项属性
    filterAttrClass: function (item, i, disableAttr) {
      let prodAttrDataId = item.prodAttrDataId;
      let prodAttrId = item.prodAttrId;
      let attrIndexData = [];
      if (prodAttrDataId == attrIndexData[i]) {
        attrIndexData.push("");
      }
      if (disableAttr && disableAttr.indexOf(prodAttrId + ":" + prodAttrDataId) != -1) {
        attrIndexData.push("dark");
      } else {
        attrIndexData.push("");
      }
      return attrIndexData;
    },

    /// 选项属性值
    filterAttrValue(item, i, data) {
      let attrValue = item.attrValue;
      let attrCode = data.code;
      // if (attrCode == "gasType_spec") {
      //   const typeCode = attrValue.substring(0, 1);
      //   const typeValue = bottles.filter((item) => item.dictValue == typeCode);
      //   attrValue +=`(${typeValue[0].dictLabel})`;
      // }
      if (attrCode == "hanliang") {
        const formula = /X\d+\^(\({0,1}-{0,1}\d\)){0,1}/;
        if (attrValue.indexOf("mol/mol") != -1) {
          const result = formula.exec(attrValue);
          if (result.length > 1) {
            let sup = result[1];
            if (sup.indexOf("(") != -1) {
              sup = sup.substring(1, sup.length - 1);
            }
            sup = `<sup>${sup}</sup>`;
            attrValue = attrValue.replace(result[0], result[0].replace("^" + result[1], sup));
          }
        }
      }
      return attrValue;
    },

    // 向左切换
    swiper_left() {
      if (this.i == 0) {
        return;
      }
      this.i--;
      this.$nextTick(() => {
        this.imgList = [];
        this.imgList = this.form.spu.image.slice(this.i * 4, 4 + this.i * 4);
        this.activateIndex = 0;
        if(this.imgList.length>0){
          this.activateImage = this.imgList[0].url;
        }
      });
    },
    // 图片
    activate(e, i) {
      this.activateImage = e.url;
      this.activateIndex = i;
    },
    // 向右切换
    swiper_right() {
      if (this.i == Math.floor(this.form.spu.image.length / 4)) {
        return;
      }
      this.i++;
      this.$nextTick(() => {
        this.imgList = [];
        this.imgList = this.form.spu.image.slice(this.i * 4, 4 + this.i * 4);
        if(this.imgList.length>0){
          this.activateImage = this.imgList[0].url;
        }
        this.activateIndex = 0;
      });
    },
    //商品关注
    handleSubscribe() {
      if (this.subscribe) {
        deleteshopcollectsup(this.spuId).then((x) => {
          if (x.code == 0) {
            this.subscribe = false;
          }
        });
      } else {
        insertshopcollectsup(this.spuId).then((x) => {
          if (x.code == 0) {
            this.subscribe = true;
          }
        });
      }
    },
    /**
     * 领取优惠券，单张领取
     */
    receiveCoupon(couponId) {
      if (isLogin()) {
        // 领取优惠券，单张领取
        get("api/coupon/receiveCoupon?couponId=" + couponId).then((res) => {
          var data = res.data;
          if (data.code == 0) {
            this.$notify({
              title: "领取成功",
              type: "success",
              duration: 2500,
            });
          } else if (data.code == 301) {
            var title = "";
            if (data.msg == "1") {
              title = "该优惠券不可领取";
            } else if (data.msg == "2") {
              title = "该优惠券未在领取时间内";
            } else if (data.msg == "3") {
              title = "该优惠券已领完";
            } else if (data.msg == "4") {
              title = "有已领取的该优惠券未使用";
            }
            this.$notify({
              title: title,
              type: "error",
              duration: 2500,
            });
          }
        });
      }
    },
    //数量 增加/减少 事件
    handleCounterClick(value) {
      this.counter += Number(value);
      if (this.counter == 0) {
        this.counter = 1;
      }
    },
    // 根据商品ID查询商品是否关注
    isshopcollectsup() {
      if (isLogin()) {
        isshopcollectsup(this.spuId).then((data) => {
          this.subscribe = !!eval("(" + data + ")");
        });
      }
    },
    /**
     * 根据spuId查询查询商品优惠券
     */
    getCouponList() {
      if (isLogin()) {
        get("api/coupon/getShopSpuCouponListBySppuId?spuId=" + this.spuId).then((res) => {
          this.couponList = res.data.data;
        });
      } else {
        get("f/api/frontcoupon/getShopSpuCouponListBySppuId?spuId=" + this.spuId).then((res) => {
          this.couponList = res.data.data;
        });
      }
    },
    /// 加载商品基础信息
    async getSaleAttr(a) {
      const saleRes = await getSaleAttr(this.spuId);
      const skuRes = await getSkuKeys(this.spuId);
      this.skuData = skuRes.data;
      const attrData = saleRes.productAttrVos;
      let ids = new Set();
      let datas = [];
      let keys = [];
      for (let key in this.skuData) {
        // console.log(key);
        keys.push(key);
        const sale = this.skuData[key];
        const array = JSON.parse(sale.saleAttrCode);
        for (let i = 0; i < array.length; i++) {
          const data = array[i];
          if (ids.has(data.prodAttrDataId)) {
            continue;
          }
          ids.add(data.prodAttrDataId);
          datas.push(data);
        }
      }
      this.pathKeys = keys;
      this.zufenbmd = [];
      // let len = attrData.length;
      // 还原所有单位的浓度范围,
      attrData.forEach((item, index) => {
        if (item.code == "hanliang") {
          let dataList = [];
          item.dataList.forEach((data) => {
            datas.forEach((sku) => {
              if (data.prodAttrDataId == sku.prodAttrDataId) {
                sku.attrValue.split(",").forEach((unit, index) => {
                  dataList.push(Object.assign({ ...data }, { attrValue: unit, unitIndex: index }));
                });
              }
            });
          });
          if (this.duozufen) {
            this.hanliangList = dataList;
            let tmps = [];
            let names = new Set();
            dataList.forEach((data) => {
              if (names.has(data.attrValue)) {
                return;
              }
              names.add(data.attrValue);
              tmps.push(data);
            });
            dataList = tmps;
          }
          item.dataList = dataList;
        }
        if (item.code == "qitizufen") {
          this.qitizufen = true;
          this.duozufen = item.selectType == "1" ? index : 0; // 是多组分
          if (this.duozufen) {
            item.dataList.forEach((data, index) => {
              if (index == 0) {
                this.dangqianzifu = data.prodAttrId;
              }
              this.zufenbmd.push(`${data.prodAttrId}:${data.prodAttrDataId}`);
            });
          }
        }
        if (item.code == "rongyezufen") {
          this.rongyezufen = true;
          this.duozufen = item.selectType == "1"; // 是多组分
        }
      });
      this.saleAttrGroup = saleRes.saleAttrGroup;
      this.validProhibit(saleRes.saleAttrGroup, keys);
      this.validSaleAttr(a, attrData);
      this.attrData = attrData;
    },
    /// 验证并获取当前所有组合禁用的销售属性
    validProhibit(groupList, keys) {
      for (let i = 0; i < groupList.length; i++) {
        this.disablePath = {
          ...this.disablePath,
          ...getDeepListKeys(groupList[i], keys),
        };
      }
    },
    changeunit(index, unit) {
      if (this.duozufen) {
        const isok = this.addattrValueList.some((item) => this.selectKeys[this.duozufen] == item.parent);
        if (isok) {
          this.$message.warning({
            message: "当前组分已经暂存，无法切换单位，请删除当前组分后切换单位",
          });
          return;
        }
      }
      this.TabIndex = index;
      this.unit = unit;
      this.validSaleAttr(false);
    },
    /// 验证并格式化销售属性
    validSaleAttr(defaultSelect, array) {
      if (!array) {
        array = this.attrData;
      }
      for (let i = 0; i < array.length; i++) {
        const root = array[i];
        let attrList = root.dataList;
        if (root.code == "hanliang") {
          const tmps = array[i - 1];
          this.isHanLiang = (tmps && tmps.code == "qitizufen") || false;
          attrList = attrList.filter((item) => item.unitIndex == this.TabIndex);
          attrList.forEach((item) => {
            if (this.selectKeys[i] == `${item.prodAttrId}:${item.prodAttrDataId}`) {
              const attrValue = item.attrValue;
              if (attrValue.indexOf("~") == -1) {
                let hanliang = attrValue;
                const request = /[^0-9\.]+/g.exec(hanliang);
                if (request) {
                  hanliang = hanliang.substring(0, request.index);
                }
                this.selectHanliang = hanliang;
                this.hanliang = parseFloat(hanliang);
              } else {
                const regData = /[^mol/lg%㎎³μgLELmgcs]+/gi.exec(attrValue);
                let hanliang = regData[0];
                this.selectHanliang = hanliang.substring(1, hanliang.indexOf(")"));
                this.hanliang = 0;
              }
            }
          });
        }
        if (this.duozufen && root.code == "qitizufen") {
          const selectKeys = this.selectKeys[i] || `${root.prodAttrId}:${attrList[0].prodAttrDataId}`;
          const qitiids = [];
          this.pathKeys.forEach((key, index) => {
            if (new RegExp(`([^;]+;){0,}${selectKeys};([^;]+)`).test(key)) {
              let id = RegExp.$2;
              id = id.substring(id.indexOf(":") + 1);
              if (!qitiids.includes(id)) {
                qitiids.push(id);
              }
            }
          });
          const qitizufen = this.hanliangList.filter((item) => qitiids.includes(item.prodAttrDataId + ""));

          array[i + 1].dataList = qitizufen;
        }

        if (root.code == "qitizufen") {
          this.unitlist = unitlist.filter((item) => array[i + 1].dataList.some((data) => data.attrValue.indexOf(item) != -1));
        }

        this.selectTree[i] = attrList;
        if (defaultSelect && attrList.length == 1 && root.code != "hanliang") {
          this.$set(this.selectKeys, i, `${root.prodAttrId}:${attrList[0].prodAttrDataId}`);
        }
      }
    },
    /// 选项选择
    attrSelect(i, data, code) {
      let newKey = this.getKeys(data);
      if (this.duozufen && code == "hanliang" && !this.selectKeys[i - 1]) {
        alert("多组分的气体或液体,请先选择组分再选择浓度范围");
        return;
      }
      this.selectFLag = false;
      let oldKey = this.selectKeys[i];
      if (oldKey == newKey) {
        this.$set(this.selectKeys, i, null);
      } else {
        this.$set(this.selectKeys, i, newKey);
      }
      this.validSaleAttr(true);
      this.disableAttr = [...this.lockItem, ...this.lockGroupItem];

      for (let i = 0; i < this.selectKeys.length; i++) {
        const key = this.selectKeys[i];
        if (key) {
          const data = this.disablePath[key].filter((item) => this.zufenbmd.indexOf(item) == -1);
          this.disableAttr.push(...data);
        }
      }

      if (code == "hanliang") {
        // const regData = /[^mol/lg%㎎³μg]+/gi.exec(data.attrValue);
        // if (regData && regData.length > 0) {
        //   let hanliang = regData[0];
        //   if (hanliang.indexOf("~") != -1) {
        //     this.selectHanliang = hanliang.substring(1, hanliang.indexOf(")"));
        //   } else {
        //     const request = /[^0-9]+/g.exec(hanliang);
        //     if (request) {
        //       hanliang = hanliang.substring(0, request.index);
        //     }
        //     this.selectHanliang = hanliang;
        //     this.hanliang = parseFloat(hanliang);
        //   }
        // } else {
        //   this.hanliang = 0;
        // }

        if (this.rongyezufen) {
          if (data.attrValue.indexOf("㎎/ml") != -1) {
            this.danwei = "㎎/ml";
          } else {
            this.danwei = "μg/ml";
          }
        }
      }

      if (this.duozufen && code == "qitizufen") {
        const qitizufen = this.addattrValueList.filter((item) => item.parent == newKey);
        if (qitizufen && qitizufen.length) {
          this.hanliang = qitizufen[0].attrValueStr;
          this.selectKeys[i + 1] = `${qitizufen[0].prodAttrId}:${qitizufen[0].prodAttrDataId}`;
        } else {
          this.hanliang = 0;
        }
      }

      let key = this.selectKeys.join(";");
      // //根据sku的key获取对应的sku的值
      const selectSku = this.skuData[key];
      if (selectSku) {
        this.selectSku = selectSku;
        // console.log(this.selectSku);
        this.selectFLag = true;
        //根据选中的sku的值获取对应spuId
        this.bus && this.bus.emit("attrSelect", this.selectSku.spuId);
        //更改spu的商品图片
        this.image.forEach((res, index) => {
          if (res.spuId == this.selectSku.spuId) {
            this.activateImage = res.url;
            this.activateIndex = index;
          }
        });
      }
    },
    //多组分
    async addzufen() {
      if (this.selectFLag) {
        let section = this.selectHanliang.split("~");
        if (parseFloat(this.hanliang) <= parseFloat(section[0]) || parseFloat(this.hanliang) > parseFloat(section[1])) {
          this.$message.warning({
            message: "不正确的含量,浓度必须大于" + section[0] + "且小于等于" + section[1],
          });
          return;
        }
        // this.lockItem = [];
        this.zancunprice = this.realPrice;
        this.lockValue = "";
        this.lockKeys = "";
        const addattrValueList = [...this.addattrValueList];
        for (let i = 0; i < this.attrData.length; i++) {
          // 除去多分组的属性处置
          if (i < this.duozufen) {
            if (this.lockValue) {
              this.lockValue += ",";
              this.lockKeys += ";";
            }
            const array = this.selectTree[i];
            array.forEach((item) => {
              const key = `${item.prodAttrId}:${item.prodAttrDataId}`;
              const id = this.selectKeys[i].split(":")[1];
              if (item.prodAttrDataId + "" == id) {
                this.lockValue += item.attrValue;
                this.lockKeys += this.selectKeys[i];
              }
              if (!this.lockItem.includes(key)) {
                this.lockItem.push(key);
              }
            });
          }
          // 含量属性处置
          if (i > this.duozufen) {
            const array = this.selectTree[i];
            array.forEach((item) => {
              const key = `${item.prodAttrId}:${item.prodAttrDataId}`;
              const id = this.selectKeys[i].split(":")[1];
              if (item.prodAttrDataId + "" == id) {
                const edit = addattrValueList.some((item) => item.prodAttrDataId + "" == id);
                if (edit) {
                  addattrValueList.forEach((item) => {
                    if (item.prodAttrDataId + "" == id) {
                      item.attrValueStr = this.hanliang;
                    }
                  });
                  return;
                }
                addattrValueList.push({
                  prodAttrId: item.prodAttrId,
                  prodAttrDataId: item.prodAttrDataId,
                  attrCode: item.attrCode,
                  attrValue: item.attrValue,
                  attrValueStr: this.hanliang,
                  danwei: this.unitlist[this.TabIndex],
                  parent: this.selectKeys[i - 1],
                });
              }
              if (!this.lockItem.includes(key)) {
                this.lockItem.push(key);
              }
            });
          }
        }
        this.addattrValueList = addattrValueList;
        await this.lockInternalStandard();
        this.disableAttr.push(...this.lockItem);
        this.lock = true;
        return;
      }
      this.$message.warning({
        message: "请先选择商品属性或填写确认含量",
      });
    },
    // 多商品气体组合判断
    async lockInternalStandard() {
      this.lockGroupItem = [];
      // 列出所有暂存的pathKeys
      const selectKeys = this.addattrValueList.map((item) => this.lockKeys + `;${item.parent};${item.prodAttrId}:${item.prodAttrDataId}`);
      // 查询当前组合下的支持额 sku
      const res = await getSkuIdByVerifyKeyVo2(this.spuId, selectKeys);
      // 白名单暂存
      let whiteList = [];
      // 循环判断当前选项是否被暂存，暂存的不会加入白名单
      res.data.attrList.forEach(({ attrCode }) => {
        const item = this.addattrValueList.find((item) => `${item.prodAttrId}:${item.prodAttrDataId}` == attrCode);
        if (!item) {
          whiteList.push(attrCode);
        }
      });
      // 将白名单中的黑名单移除
      whiteList = whiteList.filter((key) => !this.lockGroupItem.includes(key));
      // 排列获取黑名单
      const blackList = this.saleAttrGroup[this.duozufen + 1].filter((key) => !whiteList.includes(key));
      this.lockGroupItem.push(...blackList);
      this.disableAttr.push(...this.lockGroupItem);
      // 获取所有的SKU
      const skuList = res.data.skuList;
      // 获取SKU内的组合和分组满足条数(不重复的)
      const sjInfoList = [];
      skuList.forEach((item) => {
        if (!sjInfoList.some((res) => res.skuIdentifying == item.skuIdentifying && res.countSum == item.countSum)) {
          sjInfoList.push({
            skuIdentifying: item.skuIdentifying,
            countSum: item.countSum,
          });
        }
      });
      // 获取最小的分组满足条数
      const minGroupNum = Math.min(...sjInfoList.map((item) => item.countSum));
      // 获取最小分组满足条数的组和标识
      const minGroupList = sjInfoList.filter((item) => item.countSum == minGroupNum);
      // 判断改组是否可以满足任意
      if (minGroupList && minGroupList.length > 1) {
        this.nextOptions = true;
      } else {
        this.nextOptions = false;
      }
      const minGroupId = minGroupList[0].skuIdentifying;
      skuList
        .filter((item) => item.skuIdentifying == minGroupId)
        .forEach((item) => {
          this.skuData[item.key] = { ...this.skuData[item.key], ...item };
        });
      this.$forceUpdate();
    },
    //删除当前暂存组分
    async delzufen(index) {
      const data = this.addattrValueList.splice(index, 1);
      /// 查询符合当前选中组分的浓度范围（无视当前单位）
      const qitiids = [];
      this.pathKeys.forEach((key, index) => {
        if (new RegExp(`([^;]+;){0,}${data[0].parent};([^;]+)`).test(key)) {
          let id = RegExp.$2;
          id = id.substring(id.indexOf(":") + 1);
          if (!qitiids.includes(id)) {
            qitiids.push(id);
          }
        }
      });
      /// 获取当前组分的浓度范围
      const qitizufen = this.hanliangList.filter((item) => qitiids.includes(item.prodAttrDataId + ""));
      qitizufen.forEach((item) => {
        const key = `${item.prodAttrId}:${item.prodAttrDataId}`;
        const index = this.lockItem.indexOf(key);
        if (index != -1) {
          this.lockItem.splice(index, 1);
          this.disableAttr.splice(index, 1);
        }
      });
      if (this.addattrValueList.length == 0) {
        this.lockItem = [];
        this.disableAttr = [];
        for (let i = 0; i < this.selectKeys.length; i++) {
          const key = this.selectKeys[i];
          if (key) {
            const data = this.disablePath[key].filter((item) => this.zufenbmd.indexOf(item) == -1);
            this.disableAttr.push(...data);
          }
        }
        this.lock = false;
      }
      await this.lockInternalStandard();
    },
    /// 验证是否可以提交
    validSubmit() {
      let spuKeys = "";
      for (var i = 0; i < this.attrData.length; i++) {
        if (spuKeys) {
          spuKeys += ";";
        }
        spuKeys += this.selectKeys[i];
        if (this.selectKeys[i] == null) {
          this.$message.warning({
            message: "请选择商品属性",
          });
          return false;
        }
      }
      if (this.duozufen) {
        if (this.nextOptions) {
          this.$message.warning({
            message: "请确认符合气体规则是否均暂存",
          });
          return false;
        }
        // if (!this.dzfDeep) {
        //   this.dzfDeep = this.attrData[this.duozufen].dataList.length;
        // }
        // if (this.addattrValueList.length != this.dzfDeep) {

        // }
      } else {
        if (this.qitizufen) {
          if (this.selectHanliang.indexOf("~") != -1) {
            let section = this.selectHanliang.split("~");
            if (parseFloat(this.hanliang) <= parseFloat(section[0]) || parseFloat(this.hanliang) > parseFloat(section[1])) {
              this.$message.warning({
                message: "不正确的含量,浓度必须大于" + section[0] + "且小于" + section[1],
              });
              return false;
            }
          } else {
            if (parseFloat(this.hanliang) != parseFloat(this.selectHanliang)) {
              this.$message.warning({
                message: "不正确的含量,高纯气体含量必须为" + this.selectHanliang,
              });
              return false;
            }
          }
        }
      }
      if (!this.counter) {
        this.$message.warning({
          message: "请输入购买数量",
        });
        return false;
      }
      
      //验证产品数量
      if (this.counter >= this.selectSku.inventory) {
        this.$message.warning({
          message: "库存不足",
        });
        return false;
      }
      return spuKeys;
    },
    /// 生成商品信息
    getOrderList(spuKeys) {
      if (this.duozufen) {
        const orderTemp = [];
        this.addattrValueList.forEach((item) => {
          const lockKeys = this.lockKeys + `;${item.parent};${item.prodAttrId}:${item.prodAttrDataId}`;
          const order = this.skuData[lockKeys];
          if (!order) {
            this.$message.warning({
              message: "未能找到商品信息",
            });
            return;
          }
          orderTemp.push({
            spuId: order.spuId,
            skuId: order.skuId,
            num: this.counter,
            value: `${item.attrValueStr}${item.danwei}`,
            storeId: this.form.spu.basic.storeId,
            remark: 1,
            contentValue: `${item.attrValueStr}${item.danwei}`,
            value: `${item.attrValueStr}${item.danwei}`,
            key: lockKeys,
          });
        });
        return orderTemp;
      } else {
        const order = this.skuData[spuKeys];
        const danwei = this.unitlist[this.TabIndex];
        if (!order) {
          this.$message.warning({
            message: "未能找到商品信息",
          });
          return;
        }
        return [
          {
            spuId: order.spuId,
            skuId: order.skuId,
            num: this.counter,
            value: `${this.hanliang}${danwei}`,
            contentValue: `${this.hanliang}${danwei}`,
            remark: 0,
            storeId: this.form.spu.basic.storeId,
          },
        ];
      }
    },
    /// 添加购物车
    async handleSettlementCart() {
      const spuKeys = this.validSubmit();
      if (spuKeys) {
        let data = this.getOrderList(spuKeys);
        if (!data) {
          return;
        }
        let result = undefined;
        if (this.duozufen) {
          result = await moreinsertCart(data);
        } else {
          result = await singleinsertCart(data[0]);
        }
        if (result.code == 0) {
          this.$notify({
            title: "添加成功",
            type: "success",
            duration: 2500,
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 600);
        }
      }
    },
    /// 购买流程
    doBuy() {
      const spuKeys = this.validSubmit();
      if (spuKeys) {
        const orderTemp = this.getOrderList(spuKeys);
        if (!orderTemp) {
          return;
        }
        submitOrderTemp(orderTemp).then((res) => {
          const { href } = this.$router.resolve({
            name: "settlement",
          });
          window.open(href, "_blank");
        });
      }
    },
    buyjifen() {
      const spuKeys = this.validSubmit();
      if (spuKeys) {
        const orderTemp = this.getOrderList(spuKeys);
        if (!orderTemp) {
          return;
        }
        submitOrderTemp(orderTemp).then((res) => {
          this.$router.push({
            name: "settlement",
            query: {
              jifen: 1,
              spuId: this.spuId,
            },
          });
        });
      }
    },
  },
};

// 获取每个选项在组合中被禁止的其他选项
// 其逻辑为，
// 1、当前分类位置的选项进行占位置换，并去重
// 2、再将当前分类的选项带入去重的组合中，查找组合是否已经存在
// 3、如果组合存在。拆分组合的选项，加入白名单中（alist）并移除黑名单（nlist）内的选项
// 4、如果组合不存在，拆分组合的选项，判断是否在白名单中（alist），如果不在白名单中增加到黑名单（nlist）
// 5、将当前分类的数据以 { 选项1: [禁用的其他选项],选项2: [禁用的其他选项] } 返回，并记录到this中
/// =================================================================================================
/// 剩余主体部分禁用逻辑
/// =================================================================================================
// 6、判断是否是多组分气体，是的话，增设全局白名单（zufenbmd）并将都组分的所有气体加入到白名单中
// 7、选中某个选项后，将选项作为key直接获取被禁用的其他选项，先判断是否在白名单中（zufenbmd），不在的话放到禁用列表中（disableAttr）
function getDeepListKeys(groups, keys) {
  // 去重列表（暂存）
  const set = new Set();
  // 获取除了本层级之外的其他层级组合（本层级是指例如不确定度这种大选项）
  for (let i = 0; i < keys.length; i++) {
    // 将本层级的所有数据失焦（失焦将部分信息模糊处理）
    const key = keys[i].replace(new RegExp(groups.join("|")), "{}");
    // 判断移除本层级后的组合是否已经存在
    if (!set.has(key)) {
      // 不存在则加入
      set.add(key);
    }
  }
  // console.log(set);
  // 格式化去重列表（该列表得到结果为除去本层级的其他不重复层级组合）
  const list = Array.from(set);
  // 层级禁止暂存（一个层级按选项进行分割）
  let keyDisables = {};
  // 循环选项
  for (let j = 0; j < groups.length; j++) {
    // 当前层级禁止列表
    const nList = [];
    // 当前层级允许列表
    const aList = [];
    // 循环去重列表
    for (let i = 0; i < list.length; i++) {
      // 将占位符选项替换为当前选项
      const key = list[i].replace("{}", groups[j]);
      // 分割组合
      const keySplit = key.split(";");
      // 判断当前组合是否被禁止
      if (!keys.some((item) => item == key)) {
        // 被禁止，拆分被禁止的组合
        for (let j = 0; j < keySplit.length; j++) {
          // 判断选项是否在被禁止列表和被允许列表中
          // 判断选项被禁止列表冲存在则不添加（去重）
          // 判断选项被允许列表冲存在则不添加（被允许了就不禁止）
          if (!aList.includes(keySplit[j]) && !nList.includes(keySplit[j])) {
            nList.push(keySplit[j]);
          }
        }
      } else {
        // 未被禁止，拆分未被禁止的组合
        for (let j = 0; j < keySplit.length; j++) {
          // 将选项存放至允许列表
          aList.push(keySplit[j]);
          // 判断选项是否在被禁止列表中
          if (nList.includes(keySplit[j])) {
            // 如果存在则移除（为了少写一个大循环）
            nList.splice(nList.indexOf(keySplit[j]), 1);
          }
        }
      }
    }
    // console.log("nList ===> ", groups[j], nList);
    keyDisables[groups[j]] = nList;
  }
  // console.log("===> ", keyDisables);
  return keyDisables;
}

// 1、所有的气瓶规格增加编码 “gasType_spec”
// 2、多组分和浓度范围必须在最后且浓度范围必须在多组分后边
