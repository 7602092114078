<template>
  <div class="introduce w-1200">
    <div class="introduce-left">
      <div class="introduce-info">
        <div class="introduce-info--title">
          <span class="introduce-info--title-bold">{{ form && form.name }}</span>
        </div>
        <div class="introduce-info--content">
          <div class="btn-content">
            <button class="btn btn-store" @click="handleMenuClickHome">
              <svg class="btn--content" height="16">
                <image x="0" y="2" xlink:href="../../assets/store/jinru.png" width="14" height="14"></image>
                <text x="16" y="13">进入店铺</text>
              </svg>
            </button>
            <button class="btn btn-follow" @click="handleSubscribe">
              <svg class="btn--content" height="16">
                <image x="0" y="2" xlink:href="../../assets/details/follow_02.png" width="14" height="14" v-if="subscribe"></image>
                <image x="0" y="2" xlink:href="../../assets/details/follow_01.png" width="14" height="14" v-else></image>
                <text x="18" y="13">{{ subscribe ? "已关注" : "关注" }}</text>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- <div class="introduce-info">
        <div class="introduce-info--title">
          <span>店内分类</span>
        </div>
        <div class="introduce-info--content">
          <tclassify></tclassify>
        </div>
      </div> -->
    </div>
    <div class="introduce-right">
      <div class="synopsis">
        <el-tabs v-model="activeName">
          <el-tab-pane label="商品介绍" name="first">
            <div v-if="decript" class="foo">
              <div v-html="decript"></div>
            </div>
            <div v-else class="foo">
              <div v-html="spu.decript"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="规格与包装" name="second">
            <div class="second-content">
              <dl v-for="(item, index) in attrList" :key="index + 'attr'">
                <dt>{{ item.attrName }}</dt>
                <dd>{{ item.attrValue }}</dd>
              </dl>
              <dl v-for="(item, index) in attrData" :key="index + 'data'">
                <dt>{{ item.name }}</dt>
                <dd>
                  <span v-for="(item2, index2) in item.dataList" :key="index2">{{ item2.attrValue }}</span>
                </dd>
              </dl>
            </div>
          </el-tab-pane>
          <el-tab-pane label="售后保障" name="third">
            <div v-if="saleWarranty">
              <div v-html="saleWarranty"></div>
            </div>
            <div v-else>
              <div v-html="spu.saleWarranty"></div>
            </div>
            <!-- <div class="third-content">
              <h4>厂家服务</h4>
              <p>
                本商品质保周期为1年质保，在此时间范围内可提交维修申请，具体请以厂家服务为准。
                如因质量问题或故障，凭厂商维修中心或特约维修点的质量检测证明，享受7日内退货，15日内换货，15日以上在质保期内享受免费保修等三包服务！
                (注:如厂家在商品介绍中有售后保障的说明,则此商品按照厂家说明执行售后保障服务。)
                您可以查询本品牌在各地售后服务中心的联系方式，请点击这儿查询......
              </p>
              <h4>承诺</h4>
              <p>
                平台卖家销售并发货的商品，由平台卖家提供发票和相应的售后服务。请您放心购买！
                注：因厂家会在没有任何提前通知的情况下更改产品包装、产地或者一些附件，本司不能确保客户收到的货物与商城图片、产地、附件说明完全一致。只能确保为原厂正货！并且保证与当时市场上同样主流新品一致。若本商城没有及时更新，请大家谅解！
              </p>

              <h4>正品行货</h4>
              <p>
                商城向您保证所售商品均为正品行货，自营商品开具机打发票或电子发票。
              </p>
              <h4>无忧退货</h4>
              <p>
                客户购买自营商品7日内（含7日，自客户收到商品之日起计算），在保证商品完好的前提下，可无理由退货。（部分商品除外，详情请见各商品细则）
              </p>
            </div> -->
          </el-tab-pane>
          <el-tab-pane :label="'商品评价(' + commentCount + ')'" name="fourth">
            <evaluate ref="comment"></evaluate>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import store_Default from "../store/mixins-default";
import { selShopStoreInfo, insertCollectStore, cancelstore, isfollowstore, getCommentSpuIdCount } from "@/api/store";
import { isLogin } from "../../utils/public";
import evaluate from "./details-evaluate";
import { getSpuAttrs } from "@/api/spu";
import { get } from '@/utils/request';
import tclassify from "../store/store-classify";
import { getSpuInfo } from "@/api/spu";
import { getSaleAttr } from "@/api/productAttr";
export default {
  inject: ["reload", "bus"],
  mixins: [store_Default],
  components: {
    evaluate: evaluate,
    tclassify,
  },
  props: ["spu"],

  data() {
    return {
      form: {},
      subscribe: false,
      activeName: "first",
      attrList: [],
      commentCount: 0,
      commodityAvg: 0,
      commodityHp: 0,
      commodityZp: 0,
      commodityCp: 0,
      sku: "",
      decript: "",
      saleWarranty: "",
      attrData: [],
      attrData1: [],
    };
  },
  created() {
    if (isLogin()) {
      isfollowstore(this.storeId).then((data) => {
        this.subscribe = !!eval("(" + data + ")");
      });
    }
    selShopStoreInfo(this.storeId).then((data) => {
      this.form = data;
    });
    getSpuAttrs(this.spu.spuId).then((res) => {
      this.attrList = res.data;
    });
    getSaleAttr(this.spu.spuId).then((res) => {
      this.attrData = JSON.parse(JSON.stringify(res.productAttrVos));
      this.attrData1 = res.productAttrVos;
    });
    this.getCommentCount(this.spu.spuId);
    this.bus.on("attrSelect", (msg) => {
      this.getdecript(msg);
    });
  },

  beforeCreate() {},
  methods: {
    getdecript(e) {
      getSpuInfo(e).then((res) => {
        this.decript = res.data.decript;
        this.saleWarranty = res.data.saleWarranty;
      });
      getSpuAttrs(e).then((rew) => {
        this.attrList = rew.data;
      });
      this.getCommentCount(e);
    },
    introduceTab(index) {
      this.isEvaluate = index;
      var that = this;
      setTimeout(function () {
        that.$refs["anchor" + index].scrollIntoView(true);
      }, 100);
    },
    evaluate() {
      this.isEvaluate = 3;
    },
    handleSubscribe() {
      if (this.subscribe) {
        cancelstore(this.storeId).then((x) => {
          this.subscribe = false;
        });
      } else {
        insertCollectStore(this.storeId, this.form.name).then((x) => {
          this.subscribe = true;
        });
      }
    },
    handleMenuClickHome() {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     sid: this.storeId,
      //   },
      // });

      const { href } = this.$router.resolve({
        name: "store_index",
        params: {
          sid: this.storeId,
        },
      });
      window.open(href, "_blank");
    },
    getCommentCount(spuId) {
      setTimeout(() => {
        getCommentSpuIdCount(spuId).then((data) => {
          this.$refs.comment.commentCount = this.commentCount = data.commodityCount;
          this.$refs.comment.commodityAvg = this.commodityAvg = data.commodityAvg;
          this.$refs.comment.commodityHp = this.commodityHp = data.commodityHp;
          this.$refs.comment.commodityZp = this.commodityZp = data.commodityZp;
          this.$refs.comment.commodityCp = this.commodityCp = data.commodityCp;
        });
        this.$refs.comment.spuId = spuId;
        this.$refs.comment.getCommentCount();
        this.$refs.comment.getorder("all");
      }, 10);
    },
    setCommentCount() {
      this.commentCount = this.$refs.comment.commentCount;
    },
  },
};
</script>

<style scoped>
.foo >>> table {
  border-collapse: collapse;
  width: 100% !important;
  margin-left: 0px !important;
}
.foo >>> td {
  border: 1px solid #000000;
}
.third-content {
  font-size: 12px;
  color: #666;
}

.second-content > dl {
  color: #666;
  font-size: 12px;
  overflow: hidden;
  margin: 0;
}

.second-content dt {
  float: left;
  width: 220px;
  text-align: right;
  margin-right: 60px;
}

.second-content dd {
  margin-bottom: 12px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.second-content dd > span {
  margin-right: 10px;
}
</style>

<style>
.synopsis .el-tabs__item.is-active,
.synopsis .el-tabs__item:hover {
  color: #1950ff;
}

.synopsis .el-tabs__active-bar {
  background-color: #1950ff;
}
</style>

<style lang="scss" scoped>
.introduce {
  width: 1252px;
  min-height: 1057px;
  margin: auto;
  background: transparent;
  margin-bottom: 40px;

  display: flex;
  flex-direction: row;

  &-left {
    display: inline-block;
    flex: 221px;
    width: 221px;
    height: auto;
  }

  &-right {
    flex: 100;
    height: auto;
  }

  &-info {
    margin-top: 18px;
    background: #fff;

    &--title {
      padding: 19px 8px;

      &-bold {
        font-weight: 600;
      }
    }

    &--content {
      border-top: 1px solid #ededed;
      position: relative;

      > image {
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
      text {
        cursor: pointer;
      }
    }
  }

  .btn-content {
    width: 168px;
    margin: 20px auto 0px auto;
    padding-bottom: 20px;

    .btn {
      height: 30px;
      width: 84px;
      padding: 1px 4px;
      background: transparent;
      background-image: url("../../assets/details/store_btn.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border: 0px;
      position: relative;

      &:focus {
        outline: none;
      }

      &--content {
        position: absolute;

        font-size: 0.75em;
        width: 70px;

        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;

        > svg {
          > image {
            x: 0;
            y: 1;
            width: 14;
            height: 14;
          }
        }

        // width: 100%;
        // height: 100%;
      }
    }
  }

  .search {
    padding: 19px 8px;

    &-item {
      width: 100%;
      margin-bottom: 15px;

      &-text {
        display: inline-block;
        width: 70px;
      }

      &-content {
        display: inline-block;
        width: calc(100% - 70px);

        .content-input {
          width: calc(100% - 12px);
          height: 28px;
          border: 0px;
          background: transparent;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          padding: 0px 6px;

          &:focus {
            outline: none;
          }

          &-search {
            background-image: url("../../assets/details/store_search.png");
          }

          &-money {
            background-image: url("../../assets/details/store_search_money.png");
          }
        }
      }
    }

    &-money {
      display: flex;
      flex-direction: row;

      > div {
        display: inline-block;
      }
    }

    .search-btn {
      width: 59.6px;
      height: 28px;
      border: 0px;
      background: transparent;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../../assets/details/store_search_button.png");

      &:focus {
        outline: none;
      }
    }
  }

  .synopsis {
    margin-top: 20px;
    margin-left: 20px;
    background: #fff;
    padding: 25px;
    min-height: calc(100% - 70px);

    &-tab {
      margin-bottom: 20px;

      > ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        > li {
          &.activate {
            color: #1950ff;
          }

          display: inline-block;
          padding: 0px 12px;
          position: relative;
          cursor: pointer;

          &:after {
            position: absolute;
            right: -5px;
            content: "|";
            color: #bababa;
          }

          &:first-of-type {
            padding-left: 0px;
          }

          &:last-of-type {
            padding-right: 0px;

            &:after {
              content: "";
            }
          }
        }
      }
    }

    &-content {
      padding: 10px;

      .content {
        width: 100%;

        * {
          max-width: 100%;
        }
      }

      .agreement {
        padding-top: 10px;

        &-title {
          margin: 20px 0px;
          color: #4c70fc;
          font-weight: 700;
        }

        &-content {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
