function Wapper() {
  const event = new EventTarget();
  this.dispatchEvent = event.dispatchEvent.bind(event);
  this.addEventListener = event.addEventListener.bind(event);
  this.removeEventListener = event.removeEventListener.bind(event);
}

Wapper.prototype.emit = function (name, data) {
  this.dispatchEvent(new CustomEvent(name, { detail: data }));
};
Wapper.prototype.on = function (name, fn) {
  this.addEventListener(name, (e) => fn(e.detail, e));
};
Wapper.prototype.off = function (name, fn) {
  this.removeEventListener(name, (e) => fn(e.detail, e));
};
export default Wapper;
