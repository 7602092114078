import {get} from '@/utils/request';


/***
 * 获取分类下的销售属性或基本属性
 * @param prodCatId 分类id
 * @param saleType  0基本属性，1销售属性
 * @returns {*}
 */
const getProductAttr = function (prodCatId,saleType){
    return get(`/f/api/productAttr/listData/${prodCatId},${saleType}`)
        .then(res=>res.data).catch(err=>console.log(err))
}

/**
 * 获取该商品的销售规格
 * @param spuId
 * @returns {Promise<AxiosResponse<any> | void>}
 */
const getSaleAttr = function (spuId){
    return get('/f/api/productAttr/saleAttr?spuId='+spuId)
        .then(res=>res.data).catch(err=>console.log(err))
}


export {getProductAttr,getSaleAttr}
