import { getextra, post, DraftPromiseData, GetPromiseData, GetextraPromiseData, PostPromiseData, postextra } from '@/utils/request';

export function insertCart(spuId, skuId, skuCount, value, remark) {
  return postextra("api/cart/insertCart", {
    spuId: spuId,
    skuId: skuId,
    num: skuCount,
    contentValue: value,
    remark,
  });
}
export function moreinsertCart(data) {
  return postextra("api/cart/insertCartList", data);
}

export function singleinsertCart(data) {
  return postextra("api/cart/insertCart", data);
}

export function getCarts() {
  return GetPromiseData("api/cart/page", {});
}

export function deleteCart(cartSkuId) {
  return postextra("api/cart/deleteCart", {
    cartSkuId: cartSkuId,
  });
}
