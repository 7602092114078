import {get} from '@/utils/request';

const getSpuInfo = function (spuId){
    return get(`/f/api/frontspu/spuInfo?spuId=${spuId}`).then(res=>res.data).catch(err=>{console.log(err)})
}

const getSpuAttrs = function (spuId){
    return get(`/f/api/frontspu/spuAttrs?spuId=${spuId}`).then(res=>res.data).catch(err=>{console.log(err)})
}


export {getSpuInfo,getSpuAttrs}
